import { IoSyncSharp } from "react-icons/io5";
import { MdChevronLeft } from "react-icons/md";
import { Outlet, NavLink } from "react-router-dom";
import cn from "utils/cn";

const CreateAdsLayout = () => {
  return (
    <div className="flex flex-col gap-4">
      {/* back and sync button */}
      <div className="flex items-center justify-between bg-white py-2 px-3 ">
        {/* back button */}
        <button
          type="button"
          className=" capitalize flex items-center gap-1 text-[#6B7280] text-sm !font-bold leading-5"
        >
          <MdChevronLeft className="h-5 w-5" />
          <span>create ads</span>
        </button>
        <div className="flex items-center gap-2 ">
          <button type="button" className="!font-bold text-[#6B7280]">
            <IoSyncSharp className="h-5 w-5" />
          </button>
          <span className="text-[#6B7280] text-xs !font-bold leading-5 capitalize">
            last app sync
          </span>
          <span className="text-[#6B7280] text-xs !font-normal leading-5">
            Wed, Nov 2, 10:57
          </span>
        </div>
      </div>
      {/* main content box */}
      <div className="flex flex-col bg-white rounded-[4px]">
        {/* page switching buttons */}
        <div className="flex flex-row flex-wrap gap-6 border-b  px-4">
          <NavLink
            to={"/ads/create/auto-campaign"}
            className={({ isActive }) =>
              cn(
                "text-[#6B7280] text-[16px] !font-bold leading-6 py-5 hover:text-[#6B7280] focus:text-[#111827] !no-underline",
                isActive && "border-b-[3px] border-[#237804] text-[#111827] ",
              )
            }
          >
            Auto Campaign
          </NavLink>

          <NavLink
            to={"/ads/create/manual-campaign"}
            className={({ isActive }) =>
              cn(
                "text-[#6B7280] text-[16px] !font-bold leading-6 py-5 hover:text-[#6B7280] focus:text-[#111827] !no-underline",

                isActive && "border-b-[3px] border-[#237804] text-[#111827] ",
              )
            }
          >
            Manual Campaign
          </NavLink>
        </div>
        {/* content */}
        <div className="p-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default CreateAdsLayout;
