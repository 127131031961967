import CustomSelectOption from "components/CustomSelectOption/CustomSelectOption";
import { RxDragHandleDots2 } from "react-icons/rx";
import { campaignNamePlaceholder } from "./data";

const CampaignNameInput = () => {
  return (
    <div className="flex flex-col gap-1">
      <label htmlFor="campaign-name" className="flex flex-col">
        <span className="text-[#111827] leading-6 font-bold text-[16px]">
          Campaign Name
        </span>{" "}
        <p className="text-[#111827] !m-0 !font-normal text-[16px] leading-6 ">
          You can set dynamic campaign names by applying placeholders.
        </p>
      </label>

      <div className="w-full px-3 border-[1px] mt-1 py-[2px] flex items-center rounded-[4px] gap-3">
        {/* input campaign name */}
        <div className="inline-flex items-center bg-[#F9FAFB] rounded-sm p-1 gap-[5px]">
          <span>
            <RxDragHandleDots2 className="h-5 w-5" />
          </span>
          <input
            id="campaign-name"
            type="text"
            placeholder="Enter campaign name..."
            className="outline-none bg-transparent py-[2px]"
          />
        </div>
        {/* placeholder selection option */}
        <div className="w-[200px]">
          <CustomSelectOption
            field={{}}
            options={campaignNamePlaceholder}
            placeholder="Add Placeholders"
            sx={{
              "& .MuiSelect-select.MuiSelect-outlined": {
                padding: "3px 10px !important",
              },
              "& .MuiSelect-iconOutlined": {
                color: "#237804",
              },
            }}
            valueCalss="!text-[#237804] !border-[#237804]"
          />
        </div>
      </div>
    </div>
  );
};

export default CampaignNameInput;
