import { Routes, Route } from "react-router-dom";
import SignupSuccess from "./views/Signup/signupSuccess";
import ResendActicationMail from "./views/Signup/resendActivationMail";
import ResendActicationMailSuccess from "./views/Signup/resendActivationMailSuccess";
import EmailActivation from "./views/Signup/emailActivation";
import ForgorpasswordSuccess from "./views/Forgotpassword/forgorpasswordSuccess";
import Page404 from "./views/Page404";
import SignIn from "./views/Signin";
import OnboardFailed from "./views/Onboard/failed";
import OnboardSuccess from "./views/Onboard/success";
import BrowserExtension from "./views/Onboard/browserExtension";
import OnboardComplete from "./views/Onboard/onboardComplete";
import Bookshelfs from "./views/Bookshelfs";
import AutomationRules from "./views/AutomationRules";
import ChangeLogAutomationRules from "./views/AutomationRules/changeLog";
import CreateAutomationRules from "./views/AutomationRules/CreateRules";
import AdsCompaignManager from "./views/Ads/adsCompaignManager";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import SuperUser from "./views/SuperUser";
import MyProfile from "./views/MyAccount/MyProfile/myProfile";
import BillingPlan from "./views/MyAccount/BillingPlan/billingPlan";
import Notification from "./views/MyAccount/Notification/notification";
import AdsCreaction from "./views/Ads/creation";
import CreateManualCampaign from "./views/Ads/creation/components/createManualCampaign";
import CreateAutoCampaign from "./views/Ads/creation/components/createAutoCampaign";
import Faq from "./views/Faq/faq";
import setFaqData from "./views/Faq/SideTab/faqSideTab";
import CreateBothCampaign from "./views/Ads/creation/components/createBothCampaign";
import Bulkoperation from "./views/Ads/creation/components/bulkOperation";
import PlanExpriy from "./views/PlanExpriy";
import MainLayout from "./layouts/mainLayout/MainLayout";
import Login from "pages/login/Login";
import SignUp from "pages/signUp/SignUp";
import EmailVerification from "pages/emailVerification/EmailVerification";
import EmailVerificationSuccess from "pages/emailVerificationSuccess/EmailVerificationSuccess";
import ForgotPassword from "pages/forgotPassword/ForgotPassword";
import ForgotPasswordEmailSent from "pages/forgotPasswordEmailSent/ForgotPasswordEmailSent";
import ForgotPasswordResetSuccess from "pages/forgotPasswordResetSuccess/ForgotPasswordResetSuccess";
import AuthenticationLayout from "layouts/authenticationLayout/AuthenticationLayout";
import SetNewPassword from "pages/setNewPassword/SetNewPassword";
import DashboardLayout from "layouts/dashboardLayout/DashboardLayout";
import SettingsLayout from "layouts/settingsLayout/SettingsLayout";
import Profile from "pages/profile/Profile";
import BillingAndPlan from "pages/billingAndPlan/BillingAndPlan";
import AmazonConnection from "pages/amazonConnect/AmazonConnection";
import OnboardingAmazonConnect from "pages/onboardingAmazonConnect/OnboardingAmazonConnect";
import OnboardingSelectMarketPlace from "pages/onboardingSelectMarketPlace/OnboardingSelectMarketPlace";
import OnboardingInstallMarketPlace from "pages/onboardingInstallExtension/OnboardingInstallExtension";
import OnboardingStepperLayout from "layouts/onboardingLayout/OnboardingStepperLayout";
import OnboardingLayout from "layouts/onboardingLayout/OnboardingLayout";
import AddNewAutomationRuleLayout from "layouts/addNewAutomationRuleLayout/AddNewAutomationRuleLayout";
import AddNewAutomationRuleApplyTo from "pages/addNewAutomationRuleApplyTo/AddNewAutomationRuleApplyTo";
import AddNewAutomationRuleIf from "pages/addNewAutomationRuleIf/AddNewAutomationRuleIf";
import AddNewAutomationRuleThen from "pages/addNewAutomationRuleThen/AddNewAutomationRuleThen";
import AddNewAutomationRuleTimeline from "pages/addNewAutomationRuleTimeline/AddNewAutomationRuleTimeline";
import CreateAdsLayout from "layouts/createAdsLayout/CreateAdsLayout";
import CreateAdsAutoCampaign from "pages/createAdsAutoCampaign/CreateAdsAutoCampaign";
import CreateAdsManualCampaign from "pages/createAdsManualCampaign/CreateAdsManualCampaign";

export const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route element={<MainLayout />}>
          <Route element={<OnboardingLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/email-verification" element={<EmailVerification />} />
            <Route
              path="/email-verification-success"
              element={<EmailVerificationSuccess />}
            />
            <Route path="/sign-up-success" element={<SignupSuccess />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/forgot-password-email-sent"
              element={<ForgotPasswordEmailSent />}
            />
            <Route path="/set-new-password" element={<SetNewPassword />} />
            <Route
              path="/forgot-password-reset-success"
              element={<ForgotPasswordResetSuccess />}
            />
            <Route path="/onboarding" element={<OnboardingStepperLayout />}>
              <Route
                path="amazon-connect"
                element={<OnboardingAmazonConnect />}
              />
              <Route
                path="select-marketplace"
                element={<OnboardingSelectMarketPlace />}
              />
              <Route
                path="install-extension"
                element={<OnboardingInstallMarketPlace />}
              />
            </Route>
          </Route>

          <Route path="/email-activation" element={<EmailActivation />} />

          <Route element={<AuthenticationLayout />}>
            <Route element={<DashboardLayout />}>
              <Route
                path="/"
                element={
                  <p className="text-xl text-am-secondary text-center">
                    New Page
                  </p>
                }
              />
              <Route path="/account" element={<SettingsLayout />}>
                <Route path="profile" element={<Profile />} />
                <Route path="billing-plan" element={<BillingAndPlan />} />
                <Route path="amazon-connect" element={<AmazonConnection />} />
              </Route>
              <Route path="/ads/create" element={<CreateAdsLayout />}>
                <Route
                  path="auto-campaign"
                  element={<CreateAdsAutoCampaign />}
                />
                <Route
                  path="manual-campaign"
                  element={<CreateAdsManualCampaign />}
                />
              </Route>
              <Route
                path="/automation-rule/add"
                element={<AddNewAutomationRuleLayout />}
              >
                <Route
                  path="apply-to"
                  element={<AddNewAutomationRuleApplyTo />}
                />
                <Route path="if" element={<AddNewAutomationRuleIf />} />
                <Route path="then" element={<AddNewAutomationRuleThen />} />
                <Route
                  path="timeline"
                  element={<AddNewAutomationRuleTimeline />}
                />
              </Route>
              <Route path="/automation-rule">
                <Route
                  path="manager"
                  element={
                    <div className="h-full grid place-content-center bg-white">
                      <p className="text-2xl">Rule Manager Coming Soon...</p>
                    </div>
                  }
                />
              </Route>
              <Route
                path="/campaign/manager"
                element={
                  <div className="h-full grid place-content-center">
                    <p className="text-2xl">Campaign Manager Coming Soon...</p>
                  </div>
                }
              />
              <Route
                path="/campaign/add"
                element={
                  <div className="h-full grid place-content-center">
                    <p className="text-2xl">Add Campaign Coming Soon...</p>
                  </div>
                }
              />
            </Route>
          </Route>
          <Route path="/plan-expriy" element={<PlanExpriy />} />

          <Route
            path="/resend-activation-mail"
            element={<ResendActicationMail />}
          />
          <Route
            path="/resend-activation-mail-success"
            element={<ResendActicationMailSuccess />}
          />

          <Route
            path="/forgot-password/success"
            element={<ForgorpasswordSuccess />}
          />
          <Route path="/set-new-password" element={<SetNewPassword />} />
          <Route path="/admin" element={<SuperUser />} />
          <Route path="/sign-in" element={<SignIn />} />
          {/* <Route path="/onboarding" element={<Onboard />} /> */}
          <Route path="/onboarding/success" element={<OnboardSuccess />} />
          <Route path="/onboarding/failed" element={<OnboardFailed />} />
          <Route
            path="/onboarding/browser-extension"
            element={<BrowserExtension />}
          />
          <Route path="/onboarding/finish" element={<OnboardComplete />} />

          <Route path="/faq" element={<Faq setFaqData={setFaqData} />} />
          <Route path="/bookshelf" element={<Bookshelfs />} />

          <Route path="/ads/automation-rules" element={<AutomationRules />} />
          <Route
            path="/ads/automation-rules/change-log/:id"
            element={<ChangeLogAutomationRules />}
          />
          <Route
            path="/ads/create-automation-rules/:id"
            element={<CreateAutomationRules />}
          />
          <Route
            path="/ads/ads-campaign-manager"
            element={<AdsCompaignManager />}
          />

          <Route path="/ads/ads-creation" element={<AdsCreaction />} />
          <Route
            path="/ads/ads-creation/auto-manual-campaign/:asin/:keyword"
            element={<CreateBothCampaign />}
          />
          <Route
            path="/ads/ads-creation/auto-campaign/:asin/:id"
            element={<CreateAutoCampaign />}
          />
          <Route
            path="/ads/ads-creation/auto-campaign/:asin"
            element={<CreateAutoCampaign />}
          />
          <Route
            path="/ads/ads-creation/manual-campaign/:asin/:keyword"
            element={<CreateManualCampaign />}
          />
          <Route
            path="/ads/ads-creation/manual-campaign/:asin/:keyword/:id"
            element={<CreateManualCampaign />}
          />
          <Route
            path="/ads/ads-creation/auto-manual-campaign/:asin"
            element={<CreateBothCampaign />}
          />
          <Route
            path="/ads/ads-creation/blukOperation"
            element={<Bulkoperation />}
          />
          <Route path="/my-account/profile" element={<MyProfile />} />
          <Route path="/my-account/billing" element={<BillingPlan />} />
          <Route path="/my-account/notification" element={<Notification />} />
          <Route
            path="/my-account/amazon-connection"
            element={<AmazonConnection />}
          />

          {/* Private Route */}
          {/* <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      /> */}
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default App;
