export type Person = {
    id: string;
    paymentMethod: string;
    plan: string;
    issuedDate: string;
    ammount: string;
};

export const data = [
    {
        id: 'sfa33662gyhsh',
        paymentMethod: 'Murray',
        plan: '261 Erdman Ford',
        issuedDate: 'East Daphne',
        ammount: 'Kentucky',
    },
    {
        id: '2387lkh566j67',
        paymentMethod: 'Kohler',
        plan: '769 Dominic Grove',
        issuedDate: 'Columbus',
        ammount: 'Ohio',
    },
    {
        id: '12we356gg466',
        paymentMethod: 'Reinger',
        plan: '566 Brakus Inlet',
        issuedDate: 'South Linda',
        ammount: 'West Virginia',
    },
    {
        id: '63yy577gye7',
        paymentMethod: 'McCullough',
        plan: '722 Emie Stream',
        issuedDate: 'Lincoln',
        ammount: 'Nebraska',
    },
    {
        id: '136y47733g',
        paymentMethod: 'Frami',
        plan: '32188 Larkin Turnpike',
        issuedDate: 'Charleston',
        ammount: 'South Carolina',
    },
];
