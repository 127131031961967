import CustomSelectOption from "components/CustomSelectOption/CustomSelectOption";
import Datepicker from "./Datepicker";

const DropdownInputs = () => {
  return (
    <div className="grid grid-cols-3 gap-4">
      <SelectOption
        datePicker={false}
        title="Portfolio"
        placeholder="Choose portfolio"
      />

      <SelectOption
        datePicker={true}
        title="Start Date"
        placeholder="Select date"
      />

      <SelectOption
        datePicker={true}
        title="End Date"
        placeholder="Select date"
      />
    </div>
  );
};

const SelectOption = ({
  placeholder,
  title,
  datePicker,
}: {
  title: string;
  placeholder: string;
  datePicker: boolean;
}) => {
  return (
    <div className="flex flex-col gap-2">
      <h3 className="text-[#111827] !font-bold leading-6 text-[16px]">
        {title}
      </h3>
      <div className="">
        {datePicker ? (
          <Datepicker placeholder={placeholder} />
        ) : (
          <CustomSelectOption
            field={{}}
            options={[]}
            placeholder={placeholder}
            sx={{
              "& .MuiSelect-select.MuiSelect-outlined": {
                padding: "8px 10px !important",
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DropdownInputs;
