import { apiSlice } from "features/api/apiSlice";

export const paymentSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createPaymentUrl: builder.mutation({
            query: (data) => ({
                url: `/PaymentGateway/Stripe/CreateRecurringPaymentUrl`,
                method: "POST",
                body: data
            })
        }),
        addPaymentMethod: builder.mutation({
            query: (cardToken) => ({
                url: `/PaymentGateway/Stripe/card/Add`,
                method: "POST",
                body: cardToken
            })
        })
    })
})

export const {
    useCreatePaymentUrlMutation,
    useAddPaymentMethodMutation
} = paymentSlice;