import { Radio } from "@mui/material";
import { useState } from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import cn from "utils/cn";

const CampaignStructureOptions = () => {
  const [selectedOption, setSelectedOption] = useState();
  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row items-center leading-6 gap-2">
        <span className="text-[#111827]  font-bold text-[16px]">
          Campaign Structure Options
        </span>
        <button type="button" className="text-[#237804]">
          <IoMdInformationCircleOutline className="h-5 w-5" />
        </button>
      </div>
      {/* Structure options */}
      <div className="grid grid-cols-3 gap-4">
        <Option
          id="option1"
          setSelectedOption={setSelectedOption}
          active={selectedOption === "option1"}
          img="/campaign.svg"
        />
        <Option
          id="option2"
          setSelectedOption={setSelectedOption}
          active={selectedOption === "option2"}
          img="/campaign2.svg"
        />
        <Option
          id="option3"
          setSelectedOption={setSelectedOption}
          active={selectedOption === "option3"}
          img="/campaign3.svg"
        />
      </div>
    </div>
  );
};

const Option = ({
  id,
  setSelectedOption,
  active,
  img,
}: {
  id: string;
  setSelectedOption: any;
  active: boolean;
  img: string;
}) => {
  return (
    <div
      className={cn(
        "p-4 rounded-[4px] border-[1px] grid grid-cols-12 cursor-pointer gap-5 ",
        active && "bg-[#EAFFEC]",
      )}
      onClick={() => {
        setSelectedOption(id);
      }}
    >
      <label
        htmlFor={id}
        className="col-span-11 cursor-pointer flex items-center"
      >
        <img src={img} alt="options" className="w-full" />
      </label>
      <div className="col-span-1">
        <Radio
          id={id}
          checked={active}
          sx={{
            color: "#6B7280",
            padding: "0px",
            margin: "0px",
            marginRight: "5px",
            "&.Mui-checked": {
              color: "#237804",
            },
          }}
        />
      </div>
    </div>
  );
};

export default CampaignStructureOptions;
