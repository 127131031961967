import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import SubmitButton from 'components/submitButton/SubmitButton';
import { useAddPaymentMethodMutation } from 'features/payment/paymentSlice';
import { useState } from 'react';

const AddStripePaymentMethod = () => {
    /**-React Hooks-**/
    //states
    const [stripeAddLoading, setStripeAddLoading] = useState(false);

    /**-Stripe Hooks-**/
    const stripe = useStripe();
    const elements = useElements();

    /**-RTK-**/
    //mutations
    const [addPaymentMethod, { isLoading: addPaymentMethodLoading }] = useAddPaymentMethodMutation()

    /**-Event Handlers-**/
    const handleAddPaymentMethodSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
            return;
        }

        setStripeAddLoading(true)
        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        setStripeAddLoading(false)
        if (error) {
            console.log(error);
        } else {
            console.log("token here", paymentMethod);
            try {
                const { data } = await addPaymentMethod({ cardToken: paymentMethod?.id }).unwrap();
                console.log("response", data);
            } catch (error) {
                console.log(error);
            }
        }
    }

    return (
        <form
            onSubmit={handleAddPaymentMethodSubmit}
            className='grid gap-5'
        >
            <CardElement
                options={{
                    hidePostalCode: true,
                }}
            />
            <SubmitButton
                className='bg-gradient-to-b from-blue-400 to-blue-500 text-white py-1 rounded font-normal'
                loadingClassName='bg-gradient-to-b from-blue-400 to-blue-500 text-white py-1 rounded font-normal'
                disabledClassName='font-normal py-1'
                loaderSize={12}
                buttonText='Add'
                isLoading={stripeAddLoading || addPaymentMethodLoading}
            />
        </form>
    );
};

export default AddStripePaymentMethod;