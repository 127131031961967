import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import cn from 'utils/cn';

interface CustomReactRouterLinkProps extends NavLinkProps {
    to: string;
    className?: string;
    activeClass?: string;
}

const CustomReactRouterLink: React.FC<CustomReactRouterLinkProps> = ({
    className,
    to,
    activeClass = '',
    children,
    ...rest
}) => {
    return (
        <NavLink
            to={to}
            className={({ isActive }) =>
                cn(
                    "text-black no-underline hover:no-underline hover:text-black",
                    className,
                    isActive && activeClass
                )
            }
            {...rest}
        >
            {children}
        </NavLink>
    );
};

export default CustomReactRouterLink;