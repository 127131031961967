import { useLocation } from "react-router-dom";
import BillingHistoryTable from "./BillingHistoryTable";
import { FormControlLabel, Modal, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useForm, Controller } from "react-hook-form"
import { FaCheck } from "react-icons/fa6";
import cn from "utils/cn";
import crownImg from "assets/images/crown.png"
import SubmitButton from "components/submitButton/SubmitButton";
import { useCreatePaymentUrlMutation } from "features/payment/paymentSlice";
import { useLoggedInUserQuery } from "features/user/userSlice";
import BasicModal from "components/basicModal/BasicModal";
import AddStripePaymentMethod from "./AddStripePaymentMethod";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe('pk_test_51LQPkBFopl4E3uahxoWNSIIFhKksjYDnSoHjmwTFgylDsZ9trt60MZfkFxKd2zgmX0xLLY6xzUjeUlTIVuQLWKI6007tq2R1Sr');

const BillingAndPlan = () => {
  /**-React Router-**/
  const { state: routerState } = useLocation()

  /**-React Hooks-**/
  //states
  const [showProModal, setShowProModal] = useState(false);
  const [showAddPaymentMethodModal, setShowAddPaymentMethodModal] = useState(false);

  /**-Hook Form-**/
  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      upgradePlan: "free",
      interval: '',
      amount: 39,
      currency: "USD",
      description: "Pro Version"
    }
  })
  const selectedPlan = watch("upgradePlan")
  const selectedInterval = watch("interval")

  /**-RTK-**/
  //queries
  const { data: loggedInUserData } = useLoggedInUserQuery("")
  //mutations
  const [createPaymentUrl, { isLoading }] = useCreatePaymentUrlMutation()

  /**-Event Handlers-**/
  const handleUpgradePlanSubmit = async (data: any) => {
    try {
      const upgradePlanData = {
        ...data,
        interval: "month",
        currency: loggedInUserData?.result?.currencyTypeSelection || 'USD',
      }
      const createdPaymentUrl = await createPaymentUrl(upgradePlanData).unwrap()
      window.location.href = createdPaymentUrl?.url
    } catch (error) {
      console.log(error);
    }
  }

  /**-UseEffect-**/
  useEffect(() => {
    if (selectedPlan === 'pro') {
      setValue('interval', 'month');
    } else {
      setValue('interval', '');
      setValue("amount", 0);
    }
  }, [selectedPlan, setValue]);

  useEffect(() => {
    if (selectedInterval === 'month') {
      setValue("amount", 39);
    }
    if (selectedInterval === 'year') {
      setValue("amount", 399);
    }
  }, [selectedInterval, setValue])

  useEffect(() => {
    if (routerState?.showPlanUpgradeModal) {
      setShowProModal(true)
    }
  }, [routerState])

  return (
    <section className="grow flex flex-col gap-6">
      <h2 className="border-b border-gray-200 !leading-8 !font-bold !text-xl text-blacks m-0 pb-3">Billing & Plan</h2>
      <div className="grid grid-cols-12 gap-6">
        <div className="lg:col-span-7 border border-gray-200 p-6 rounded-md grid grid-cols-12 gap-3">
          <div className="col-span-8 grid gap-3">
            <p className="text-xl text-black font-medium">Free Plan</p>
            <div className="flex gap-1 items-center">
              <p className="mt-0 text-black text-3xl font-bold">$0</p>
              <p className="mt-0 text-2xl font-medium text-gray-500">/month</p>
            </div>
          </div>
          <div className="col-span-4 flex justify-end items-center">
            <button
              onClick={() => setShowProModal(true)}
              className="bg-am-primary text-white p-3 rounded font-bold text-base tracking-wide"
            >
              Upgrade to PRO Version
            </button>
          </div>
          <p className="col-span-12 font-medium">Free plan for monthly royalties below $500. <span className="text-[#00976D]">Upgrade Plan</span> to access all Aimosa features.</p>
          {/* upgrade plan modal */}
          <Modal
            open={showProModal}
            onClose={() => setShowProModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 min-w-80 max-w-[500px] bg-white rounded-md flex flex-col">
              <header className="border-b border-gray-200 p-3">
                <div className="flex justify-between">
                  <p className="text-xl font-bold">Upgrage to PRO Version</p>
                  <button onClick={() => setShowProModal(false)}>
                    <RxCross2 />
                  </button>
                </div>
              </header>
              <form
                onSubmit={handleSubmit(handleUpgradePlanSubmit)}
                className="grow p-3 grid gap-4"
              >
                <section>
                  <Controller
                    control={control}
                    name="upgradePlan"
                    defaultValue={"free"}
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                      return (
                        <div className="grid grid-cols-12 gap-3">
                          <label
                            htmlFor="amFree"
                            className={cn(
                              "col-span-6 cursor-pointer border rounded p-3 grid gap-2",
                              value === "free" && "bg-[#EAFFEC] border-am-primary"
                            )}
                          >
                            <div className="flex justify-between">
                              <p className={cn(
                                "text-xl font-semibold",
                                value === "free" ? "text-am-primary" : "text-black"
                              )}
                              >
                                Basic Plan
                              </p>
                              <Radio
                                id="amFree"
                                name="upgradePlan"
                                value="free"
                                inputProps={{ 'aria-label': 'free' }}
                                onChange={onChange}
                                checked={value === 'free'}
                                sx={{
                                  padding: "0px",
                                  color: "gray !important",
                                  '&.Mui-checked': {
                                    color: "green !important",
                                  },
                                }}
                              />
                            </div>
                            <div>
                              <p className={cn(
                                "text-3xl font-bold",
                                value === "free" ? "text-am-primary" : "text-black"
                              )}>FREE</p>
                              <p className={cn(
                                "mt-0 text-xs text-gray-600 tracking-wide border-b pr-2 pb-1",
                                value === "free" ? "border-am-primary-light/50" : "border-gray-200"
                              )}>Free plan for monthly royalties below $500</p>
                            </div>
                            <div>
                              <ul>
                                <li className="flex gap-2 items-center">
                                  <FaCheck className="text-lg" />
                                  <p>Benefit 1</p>
                                </li>
                              </ul>
                            </div>
                          </label>
                          <label
                            htmlFor="amPro"
                            className={cn(
                              "col-span-6 cursor-pointer border rounded p-3 grid gap-2",
                              value === "pro" && "bg-[#EAFFEC] border-am-primary"
                            )}
                          >
                            <div className="flex">
                              <div className="grow flex gap-1 items-center">
                                <img className="w-[30px]" src={crownImg} alt="" />
                                <p className={cn(
                                  "text-xl font-semibold",
                                  value === "pro" ? "text-am-primary" : "text-black"
                                )}
                                >
                                  PRO Version
                                </p>
                              </div>
                              <Radio
                                id="amPro"
                                name="upgradePlan"
                                value="pro"
                                inputProps={{ 'aria-label': 'pro' }}
                                checked={value === 'pro'}
                                onChange={onChange}
                                sx={{
                                  padding: "0px",
                                  color: "gray !important",
                                  '&.Mui-checked': {
                                    color: "green !important",
                                  },
                                }}
                              />
                            </div>
                            <div className="grid gap-2">
                              <p className={cn(
                                "flex items-center",
                                value === "pro" ? "text-am-primary" : "text-black"
                              )}
                              >
                                <span className="text-3xl font-bold">$39</span><span className="text-[16px] font-semibold mt-1">/month</span>
                              </p>
                              <div
                                className={cn(
                                  "flex justify-between items-center border-b pb-1",
                                  value === "pro" ? "border-am-primary-light/50" : "border-gray-200"
                                )}
                              >
                                <p className={cn(
                                  "flex items-center",
                                  value === "pro" ? "text-am-primary" : "text-black"
                                )}
                                >
                                  <span className="text-xl font-bold">$399</span><span className="text-xs font-semibold mt-1">/year</span>
                                </p>
                                <div>
                                  <div className="bg-gradient-to-r from-[#FFAD0E] to-[#FF820E] pl-3 pr-2 py-1 translate-x-[12px]" style={{ clipPath: "polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 7% 50%, 0% 0%)" }}>
                                    <p className="text-white font-bold text-xs">Save up to 15%</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <ul>
                                <li className="flex gap-2 items-center">
                                  <FaCheck className="text-lg" />
                                  <p>Benefit 1</p>
                                </li>
                              </ul>
                            </div>
                          </label>
                        </div>
                      )
                    }}
                  />
                </section>
                {
                  selectedPlan === "pro" && (
                    <section>
                      <p className="text-[16px] font-bold">Duration</p>
                      <Controller
                        control={control}
                        name="interval"
                        render={({ field: { onChange, onBlur, value, ref } }) => {
                          return (
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                            >
                              <FormControlLabel
                                value="month"
                                control={
                                  <Radio color="success" onChange={onChange} checked={value === 'month'} />
                                }
                                label="Monthly - $39"
                              />
                              <FormControlLabel
                                value="year"
                                control={<Radio color="success" onChange={onChange} checked={value === 'year'} />}
                                label="Yearly - $399 (Save up to 15%)"
                              />
                            </RadioGroup>
                          )
                        }}
                      />
                    </section>
                  )
                }
                <section className="flex justify-end">
                  <div className="flex gap-2">
                    <button
                      type="button"
                      className="px-8 py-2 tracking-wide font-bold text-xs text-am-primary border border-am-primary rounded"
                      onClick={() => setShowProModal(false)}
                    >
                      Cancel
                    </button>
                    <SubmitButton
                      buttonText="Go to Payment"
                      className="px-8 py-2 tracking-wide font-bold text-xs"
                      disabledClassName="px-8 py-2 tracking-wide font-bold text-xs"
                      loadingClassName="px-8 py-2 tracking-wide font-bold text-xs"
                      loaderSize={15}
                      isDisabled={watch("upgradePlan") === "pro" ? false : true}
                      isLoading={isLoading}
                      buttonType="submit"
                    />
                  </div>
                </section>
              </form>
            </div>
          </Modal>
        </div>
        <div className="lg:col-span-5 border border-gray-200 p-6 rounded-md flex flex-col">
          <div className="grid grid-cols-12">
            <div className="lg:col-span-6">
              <p className="text-black text-base font-medium">Payment Method</p>
            </div>
            <div className="lg:col-span-6 flex justify-end">
              <button
                className="hover:no-underline text-current inline-block"
                onClick={() => setShowAddPaymentMethodModal(true)}
              >
                <p className="underline underline-offset-4 !decoration-2 !decoration-am-primary text-am-primary font-bold">Add Payment Method</p>
              </button>
              <BasicModal
                showModal={showAddPaymentMethodModal}
                setShowModal={setShowAddPaymentMethodModal}
                headerTitle="Add Payment Method"
              >
                <Elements stripe={stripePromise}>
                  <AddStripePaymentMethod />
                </Elements>
              </BasicModal>
            </div>
          </div>
          <div className="grow grid place-items-center">
            <p className="font-medium text-am-aiGray">No Payment Method Added</p>
          </div>
        </div>
      </div>
      <div className="grow">
        <BillingHistoryTable />
      </div>
    </section>
  )
};

export default BillingAndPlan;
