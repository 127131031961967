import { useMemo } from 'react';
import {
    MRT_Table as MrtTable, //import alternative sub-component if we do not want toolbars
    type MRT_ColumnDef,
    useMaterialReactTable,
} from 'material-react-table';
import { data, type Person } from './exampleDummyTableData';

export const BillingHistoryTable = () => {
    const columns = useMemo<MRT_ColumnDef<Person>[]>(
        //column definitions...
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
            },
            {
                accessorKey: 'paymentMethod',
                header: 'Payment Method',
            },
            {
                accessorKey: 'plan',
                header: 'Plan',
            },
            {
                accessorKey: 'issuedDate',
                header: 'Issued Date',
            },
            {
                accessorKey: 'ammount',
                header: 'Ammount',
            },
        ],
        [],
        //end
    );

    const table = useMaterialReactTable({
        columns,
        data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        enableKeyboardShortcuts: false,
        enableColumnActions: false,
        enableColumnFilters: false,
        enablePagination: false,
        enableSorting: false,
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.background.default, //change default background color
        }),
        muiTableBodyRowProps: { hover: false },
        muiTableProps: {
            sx: {
                border: '1px solid #e5e7eb',
                caption: {
                    captionSide: 'top',
                },
            },
        },
        muiTableHeadRowProps: {
            sx: {
                boxShadow: 'none',
            },
        },
        muiTableHeadCellProps: {
            sx: {
                border: '1px solid #e5e7eb',
                fontStyle: 'italic',
                fontWeight: 'normal',
                backgroundColor: '#F9FAFB',
            },
        },
        muiTableBodyCellProps: {
            sx: {
                border: '1px solid #e5e7eb',
            },
        },
        // renderCaption: ({ table }) =>
        //     `Here is a table rendered with the lighter weight MRT_Table sub-component, rendering ${table.getRowModel().rows.length} rows.`,
    });

    //using MRT_Table instead of MaterialReactTable if we do not need any of the toolbar components or features
    return <MrtTable table={table} />;
};

export default BillingHistoryTable;