export const campaignNamePlaceholder = [
  {
    name: "Campaign Structure",
    value: "Campaign Structure",
  },
  {
    name: "Targeting Type",
    value: "Targeting Type",
  },
  {
    name: "Match Type",
    value: "Match Type",
  },
  {
    name: "ASIN",
    value: "ASIN",
  },
  {
    name: "Custom Text",
    value: "Custom Text",
  },
];
