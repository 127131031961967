import AdGroupNameInput from "./AdGroupNameInput";
import CampaignNameInput from "./CampaignNameInput";
import CampaignStructureOptions from "./CampaignStructureOptions";
import DropdownInputs from "./DropdownInputs";

const CreateAdsAutoCampaign = () => {
  return (
    <div className="flex flex-col gap-5">
      {/* campaign name input */}
      <CampaignNameInput />
      {/* ad group name */}
      <AdGroupNameInput />
      {/* campaign structure option */}
      <CampaignStructureOptions />
      {/* dropdown select options */}
      <DropdownInputs />
      {/* divider */}
      <div className="w-full h-[4px] bg-[#E5E7EB] my-5" />
    </div>
  );
};

export default CreateAdsAutoCampaign;
