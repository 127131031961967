import React from "react";

const CreateAdsManualCampaign = () => {
  return (
    <div className="flex items-center justify-center">
      CreateAdsManualCampaign
    </div>
  );
};

export default CreateAdsManualCampaign;
